
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "DynamicPage",
  computed: {
    ...mapGetters({
      page: "helper/getDynamicPage"
    })
  },
  mounted() {
    this.$store.dispatch("helper/GET_DYNAMIC_PAGE", this.$route.params.slug);
  }
});
